// @file Locales list for internationalization

interface Locale {
  standard: string
  domain: string
  html: string
  yml: string
  po: string
  rtl: boolean
  time: string
  available: boolean
  name: string
  real: boolean // Real languages are those that are in ISO 639
}

const allLocales: Locale[] = [
  {
    standard: 'ar',
    domain: 'ar',
    html: 'ar',
    yml: 'ar',
    po: 'ar',
    rtl: true,
    time: 'long',
    available: true,
    name: 'العربية',
    real: true,
  }, // Arabic
  {
    standard: 'bg',
    domain: 'bg',
    html: 'bg',
    yml: 'bg',
    po: 'bg',
    rtl: false,
    time: 'long',
    available: true,
    name: 'български',
    real: true,
  }, // Bulgarian
  {
    standard: 'bn',
    domain: 'bn',
    html: 'bn',
    yml: 'bn',
    po: 'bn',
    rtl: false,
    time: 'short',
    available: true,
    name: 'বাংলা',
    real: true,
  }, // Bengali
  {
    standard: 'ca',
    domain: 'ca',
    html: 'ca',
    yml: 'ca',
    po: 'ca',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Català',
    real: true,
  }, // Catalan
  {
    standard: 'cs',
    domain: 'cs',
    html: 'cs',
    yml: 'cs',
    po: 'cs',
    rtl: false,
    time: '%d. %B %Y %H:%M',
    available: true,
    name: 'Čeština',
    real: true,
  }, // Czech
  {
    standard: 'cy_GB',
    domain: 'cy-gb',
    html: 'cy-GB',
    yml: 'cy-GB',
    po: 'cy_GB',
    rtl: false,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'Cymraeg',
    real: true,
  }, // Welsh
  {
    standard: 'da',
    domain: 'da',
    html: 'da',
    yml: 'da',
    po: 'da',
    rtl: false,
    time: 'short',
    available: true,
    name: 'Dansk',
    real: true,
  }, // Danish
  {
    standard: 'de',
    domain: 'de',
    html: 'de',
    yml: 'de',
    po: 'de',
    rtl: false,
    time: '%d. %B %Y, %H:%M Uhr',
    available: true,
    name: 'Deutsch',
    real: true,
  }, // German
  {
    standard: 'el',
    domain: 'el',
    html: 'el',
    yml: 'el',
    po: 'el',
    rtl: false,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'Ελληνικά',
    real: true,
  }, // Greek
  {
    standard: 'en',
    domain: '',
    html: 'en',
    yml: 'en',
    po: 'en',
    rtl: false,
    time: '%b %d, %Y %I:%M%P',
    available: true,
    name: 'English (US)',
    real: true,
  }, // English
  {
    standard: 'en_GB',
    domain: 'en-gb',
    html: 'en-GB',
    yml: 'en-GB',
    po: 'en_GB',
    rtl: false,
    time: '%b %d, %Y %I:%M%P',
    available: true,
    name: 'English (UK)',
    real: true,
  }, // English
  {
    standard: 'qs_GB',
    domain: 'qs-gb',
    html: 'qs-GB',
    yml: 'qs-GB',
    po: 'qs_GB',
    rtl: false,
    time: '%b %d, %Y %I:%M%P',
    available: true,
    name: 'English (Shakespeare)',
    real: false,
  }, // Shakespearean English
  {
    standard: 'qy_GB',
    domain: 'qy-gb',
    html: 'qy-GB',
    yml: 'qy-GB',
    po: 'qy_GB',
    rtl: false,
    time: '%b %d, %Y %I:%M%P',
    available: true,
    name: 'English (Yoda)',
    real: false,
  }, // Yoda English
  {
    standard: 'es',
    domain: 'es',
    html: 'es',
    yml: 'es',
    po: 'es',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Español',
    real: true,
  }, // Spanish
  {
    standard: 'et',
    domain: 'et',
    html: 'et',
    yml: 'et',
    po: 'et',
    rtl: false,
    time: 'short',
    available: true,
    name: 'Eesti keel',
    real: true,
  }, // Estonian
  {
    standard: 'fa',
    domain: 'fa',
    html: 'fa',
    yml: 'fa',
    po: 'fa',
    rtl: true,
    time: 'long',
    available: true,
    name: 'فارسی',
    real: true,
  }, // Persian
  {
    standard: 'fi',
    domain: 'fi',
    html: 'fi',
    yml: 'fi',
    po: 'fi',
    rtl: false,
    time: '%e.%m.%Y %H.%M',
    available: true,
    name: 'Suomi',
    real: true,
  }, // Finnish
  {
    standard: 'fil',
    domain: 'fil',
    html: 'tl',
    yml: 'tl',
    po: 'tl',
    rtl: false,
    time: '%d ng %b ng %Y %H:%M',
    available: true,
    name: 'Filipino',
    real: true,
  }, // Tagalog
  {
    standard: 'fr',
    domain: 'fr',
    html: 'fr',
    yml: 'fr',
    po: 'fr',
    rtl: false,
    time: '%d %B %Y %Hh%M',
    available: true,
    name: 'Français',
    real: true,
  }, // French
  {
    standard: 'gl_ES',
    domain: 'gl-es',
    html: 'gl-ES',
    yml: 'gl-ES',
    po: 'gl_ES',
    rtl: false,
    time: '%e/%m/%y, %H:%M',
    available: true,
    name: 'Galego',
    real: true,
  }, // Galician
  {
    standard: 'he',
    domain: 'he',
    html: 'he',
    yml: 'he',
    po: 'he',
    rtl: true,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'עברית',
    real: true,
  }, // Hebrew
  {
    standard: 'hi',
    domain: 'hi',
    html: 'hi',
    yml: 'hi',
    po: 'hi',
    rtl: false,
    time: 'short',
    available: true,
    name: 'हिन्दी',
    real: true,
  }, // Hindi
  {
    standard: 'hr',
    domain: 'hr',
    html: 'hr',
    yml: 'hr',
    po: 'hr',
    rtl: false,
    time: 'short',
    available: true,
    name: 'Hrvatski',
    real: true,
  }, // Croatian
  {
    standard: 'hu',
    domain: 'hu',
    html: 'hu',
    yml: 'hu',
    po: 'hu',
    rtl: false,
    time: '%Y. %B %e., %H:%M',
    available: true,
    name: 'Magyar',
    real: true,
  }, // Hungarian
  {
    standard: 'id',
    domain: 'id',
    html: 'id',
    yml: 'id',
    po: 'id',
    rtl: false,
    time: '%d %B %Y %H.%M',
    available: true,
    name: 'Bahasa Indonesia',
    real: true,
  }, // Indonesian
  {
    standard: 'it',
    domain: 'it',
    html: 'it',
    yml: 'it',
    po: 'it',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Italiano',
    real: true,
  }, // Italian
  {
    standard: 'ja',
    domain: 'ja',
    html: 'ja',
    yml: 'ja',
    po: 'ja',
    rtl: false,
    time: 'short',
    available: true,
    name: '日本語',
    real: true,
  }, // Japanese
  {
    standard: 'ko',
    domain: 'ko',
    html: 'ko',
    yml: 'ko',
    po: 'ko',
    rtl: false,
    time: 'short',
    available: true,
    name: '한국어',
    real: true,
  }, // Korean
  {
    standard: 'lv',
    domain: 'lv',
    html: 'lv',
    yml: 'lv',
    po: 'lv',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Latviešu',
    real: true,
  }, // Latvian
  {
    standard: 'ms',
    domain: 'ms',
    html: 'ms',
    yml: 'ms',
    po: 'ms',
    rtl: false,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'Bahasa Melayu',
    real: true,
  }, // Malay
  {
    standard: 'nl',
    domain: 'nl',
    html: 'nl',
    yml: 'nl',
    po: 'nl',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Nederlands',
    real: true,
  }, // Dutch
  {
    standard: 'no',
    domain: 'no',
    html: 'no',
    yml: 'no',
    po: 'no',
    rtl: false,
    time: '%e. %B %Y, %H:%M',
    available: true,
    name: 'Norsk',
    real: true,
  }, // Norwegian
  {
    standard: 'pl',
    domain: 'pl',
    html: 'pl',
    yml: 'pl',
    po: 'pl',
    rtl: false,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'Polski',
    real: true,
  }, // Polish
  {
    standard: 'pt_BR',
    domain: 'pt-br',
    html: 'pt-BR',
    yml: 'pt-BR',
    po: 'pt_BR',
    rtl: false,
    time: '%d/%m/%y, %H:%M hs',
    available: true,
    name: 'Português',
    real: true,
  }, // Portuguese
  {
    standard: 'ro',
    domain: 'ro',
    html: 'ro',
    yml: 'ro',
    po: 'ro',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Română',
    real: true,
  }, // Romanian
  {
    standard: 'ru',
    domain: 'ru',
    html: 'ru',
    yml: 'ru',
    po: 'ru',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Pусский',
    real: true,
  }, // Russian
  {
    standard: 'sk',
    domain: 'sk',
    html: 'sk',
    yml: 'sk',
    po: 'sk',
    rtl: false,
    time: '%d.%m.%y %H:%M',
    available: true,
    name: 'Slovenčina',
    real: true,
  }, // Slovak
  {
    standard: 'sr',
    domain: 'sr',
    html: 'sr',
    yml: 'sr',
    po: 'sr',
    rtl: false,
    time: '%d %b %Y %H:%M',
    available: true,
    name: 'српски',
    real: true,
  }, // Serbian
  {
    standard: 'sv',
    domain: 'sv',
    html: 'sv',
    yml: 'sv',
    po: 'sv',
    rtl: false,
    time: '%e %b %Y %H:%M',
    available: true,
    name: 'Svenska',
    real: true,
  }, // Swedish
  {
    standard: 'th',
    domain: 'th',
    html: 'th',
    yml: 'th',
    po: 'th',
    rtl: false,
    time: 'long',
    available: true,
    name: 'ภาษาไทย',
    real: true,
  }, // Thai
  {
    standard: 'tr',
    domain: 'tr',
    html: 'tr',
    yml: 'tr',
    po: 'tr',
    rtl: false,
    time: '%e %b %Y, %H:%M',
    available: true,
    name: 'Türkçe',
    real: true,
  }, // Turkish
  {
    standard: 'uk',
    domain: 'uk',
    html: 'uk',
    yml: 'uk',
    po: 'uk',
    rtl: false,
    time: 'long',
    available: true,
    name: 'українська мова',
    real: true,
  }, // Ukranian
  {
    standard: 'ur',
    domain: 'ur',
    html: 'ur',
    yml: 'ur',
    po: 'ur',
    rtl: true,
    time: 'short',
    available: true,
    name: 'اردو',
    real: true,
  }, // Urdu
  {
    standard: 'vi',
    domain: 'vi',
    html: 'vi',
    yml: 'vi',
    po: 'vi',
    rtl: false,
    time: 'long',
    available: true,
    name: 'Tiếng Việt',
    real: true,
  }, // Vietnamese
  {
    standard: 'zh_CN',
    domain: 'zh-cn',
    html: 'zh-CN',
    yml: 'zh-CN',
    po: 'zh_CN',
    rtl: false,
    time: 'long',
    available: true,
    name: '简体中文',
    real: true,
  }, // Simplified Chinese
  {
    standard: 'zh_TW',
    domain: 'zh-TW',
    html: 'zh-TW',
    yml: 'zh-TW',
    po: 'zh_TW',
    rtl: false,
    time: 'long',
    available: true,
    name: '繁體中文',
    real: true,
  }, // Traditional Chinese
]

const availableLocales = allLocales.filter((x: Locale): boolean => x.available)

const availableRealLocales = allLocales.filter((x: Locale): boolean => x.real && x.available)

const getLocaleName = (locale: string): string => {
  return availableLocales.find((availableLocale) => availableLocale.html === locale)?.name ?? 'English (US)'
}

export { availableLocales, availableRealLocales, getLocaleName }
